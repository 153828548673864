<template>
  <q-layout view="hHh lpR fff">
    <!-- header -->
    <q-header
      id="header"
      v-if="!isLoading && (isMobile ? !noMobileHeaderPages.includes($route.name) : !noHeaderPages.includes($route.name))"
      class="row full-width bg-white q-px-sm justify-center items-center"
      elevated>
      <div class="row col-11 justify-between items-center">

        <!-- logo, name, links -->
        <div class="row items-center">

          <!-- logo, title -->
          <router-link v-if="tenantInfo.id === 18" class="row items-center" style="text-decoration: none" to="/">

            <!-- logo -->
            <q-img class="q-mr-md"
                   src="img/logo-mobile.png"
                   width="38px" height="38px">
              <template v-slot:loading>
                <q-spinner-tail
                  color="grey-4"
                  size="md"
                />
              </template>
            </q-img>

            <div class="row">
              <!-- title -->
              <div class="row col-12 text-h6 text-weight-bold text-grey-7 q-mt-xs">
<!--                {{ tenantInfo && tenantInfo.title }}-->
                حـامـیـانــه
              </div>

              <!-- slogan -->
              <div class="row col-12 text-caption text-weight-regular text-grey-7 q-ma-none q-pa-none">
                {{ tenantInfo && tenantInfo.details.slogan }}
              </div>
            </div>


          </router-link>

          <!-- logo, title -->
          <router-link v-if="tenantInfo.id !== 18" class="row q-mr-xl items-center" style="text-decoration: none" to="/">

            <!-- logo -->
            <q-img class="q-mr-md"
                   :src="tenantInfo &&  tenantInfo.logo && `${baseUrl}/cdn/download/${tenantInfo.logo.fileName}`"
                   width="38px" height="38px">
              <template v-slot:loading>
                <q-spinner-tail
                  color="grey-4"
                  size="md"
                />
              </template>
            </q-img>

            <!-- title -->
            <div class="text-subtitle1 text-weight-medium text-grey-8">
              {{ tenantInfo && tenantInfo.title }}
            </div>

          </router-link>

          <!-- links (desktop) -->
          <div v-if="!isMobile" class="row q-gutter-x-xl items-center">

            <router-link class="text-subtitle2 text-weight-regular text-grey-10 hover-primary" tag="div"
                         to="/donate"
                         style="text-decoration: none">
              مشارکت
            </router-link>

            <router-link class="text-subtitle2 text-weight-regular text-grey-10 hover-primary" tag="div"
                         to="/challenges"
                         style="text-decoration: none">
              پویش‌ها
            </router-link>

            <router-link class="text-subtitle2 text-weight-regular text-grey-10 hover-primary" tag="div"
                         to="/donation-request"
                         style="text-decoration: none">
              ثبت اهدایی
            </router-link>

            <router-link class="text-subtitle2 text-weight-regular text-grey-10 hover-primary" tag="div"
                         to="/gift"
                         style="text-decoration: none">
              یادمانه
            </router-link>

            <!--            <router-link class="text-subtitle2 text-weight-regular text-grey-10 hover-primary" tag="div" to="/contact-us"-->
            <!--                         style="text-decoration: none">-->
            <!--              تماس با ما-->
            <!--            </router-link>-->

            <!-- contact dialog -->
            <!--            <q-dialog ref="contactDialog" persistent @keypress.enter="requestContact">-->
            <!--              <div class="row bg-grey-2 q-pa-md" style="max-width: 360px; border-radius: 10px !important">-->

            <!--                &lt;!&ndash; header &ndash;&gt;-->
            <!--                <div class="row full-width q-pb-md justify-between items-center"-->
            <!--                     style="border-bottom: 1px solid lightgrey">-->

            <!--                  &lt;!&ndash; title &ndash;&gt;-->
            <!--                  <div class="text-subtitle1 text-weight-regular text-black">-->
            <!--                    تماس با ما-->
            <!--                  </div>-->

            <!--                  &lt;!&ndash; icon &ndash;&gt;-->
            <!--                  <q-icon name="la la-headset" size="sm" color="grey-8"/>-->

            <!--                </div>-->

            <!--                &lt;!&ndash; body &ndash;&gt;-->
            <!--                <div class="column full-width q-mt-xs q-mb-md q-gutter-y-md items-center">-->

            <!--                  &lt;!&ndash; info &ndash;&gt;-->
            <!--                  <div class="text-subtitle2 text-weight-regular text-center text-grey-9" style="line-height: 30px">-->
            <!--                    {{ contactRequested ? 'شماره شما با موفقیت ثبت شد. تیم حامیانه به زودی با شما در ارتباط خواهد بود' : 'لطفا شماره موبایل خود را وارد کنید' }}-->
            <!--                  </div>-->

            <!--                  &lt;!&ndash; phone number &ndash;&gt;-->
            <!--                  <q-input v-if="!contactRequested"-->
            <!--                           v-model="contactPhoneNumber"-->
            <!--                           class="full-width border-radius text-grey-4 hover-shadow"-->
            <!--                           style="border: 2px solid"-->
            <!--                           maxlength="11"-->
            <!--                           autofocus-->
            <!--                           borderless-->
            <!--                           dense-->
            <!--                  >-->
            <!--                    <template v-slot:prepend>-->
            <!--                      <q-icon class="q-ml-xs" name="la la-mobile"/>-->
            <!--                    </template>-->
            <!--                  </q-input>-->

            <!--                  &lt;!&ndash; error &ndash;&gt;-->
            <!--                  <div v-if="phoneNumberIsWrong"-->
            <!--                       class="row full-width q-pl-sm animated fadeInDown text-subtitle2 text-weight-regular text-red-13">-->
            <!--                    شماره موبایل به درستی وارد نشده است-->
            <!--                  </div>-->

            <!--                </div>-->

            <!--                &lt;!&ndash; footer &ndash;&gt;-->
            <!--                <div class="row full-width">-->

            <!--                  &lt;!&ndash; return &ndash;&gt;-->
            <!--                  <div v-if="!contactRequested" class="col-6 q-pr-sm">-->
            <!--                    <q-btn class="full-width border-radius bg-red-1 q-px-md text-weight-regular" color="primary"-->
            <!--                           label="بازگشت"-->
            <!--                           outline dense @click="$refs.contactDialog.hide"/>-->
            <!--                  </div>-->

            <!--                  &lt;!&ndash; register &ndash;&gt;-->
            <!--                  <div :class="contactRequested ? 'col-12' : 'col-6 q-pl-sm'">-->
            <!--                    <q-btn class="full-width border-radius q-px-md text-weight-light" color="primary"-->
            <!--                           :label="contactRequested ? 'متوجه شدم' : 'ثبت شماره موبایل'" unelevated dense @click="requestContact"/>-->
            <!--                  </div>-->

            <!--                </div>-->

            <!--              </div>-->
            <!--            </q-dialog>-->

          </div>

        </div>

        <!-- update notice -->
        <div v-if="!isMobile && needsUpdate && updateAppDisclaimer" class="row items-center text-grey-6">

          <!-- disclaimer -->
          <div class="q-mr-md text-subtitle2 text-weight-regular">
            نسخه جدید در دسترس است
          </div>

          <!-- update button -->
          <q-btn class="border-radius q-mr-sm q-px-md text-weight-regular" label="بروزرسانی" outline dense
                 @click="refreshPage"/>

          <!-- close button -->
          <q-btn class="border-radius q-px-md text-weight-regular" label="بعدا" outline dense
                 @click="updateAppDisclaimer = false"/>

        </div>

        <!-- search (desktop), support (mobile), social media, profile -->
        <div class="row items-center">

          <!-- search (desktop) -->
          <q-btn v-if="!isMobile && $route.name !== 'search' && tenantInfo.id === 18" class="border-radius cursor-pointer hover-primary"
                 color="grey-6" icon="la la-search"
                 flat dense @click="isSearchActive = true">
            <q-tooltip class="bg-primary border-radius text-caption text-weight-regular" transition-show="jump-up"
                       transition-hide="jump-down">
              جستجو
            </q-tooltip>
          </q-btn>

          <!-- search dialog (desktop) -->
          <q-dialog
            v-model="isSearchActive"
            transition-show="jump-down"
            transition-hide="jump-up"
            maximized
            @show="searchBoxAnimation = true"
            @hide="search = ''"
          >

            <!-- search layout -->
            <div class="row full-width full-height q-py-xl justify-center"
                 style="background-color: #00000011; backdrop-filter: blur(8px)">
              <div class="column col-8 q-py-xl">

                <!-- search input, close search -->
                <transition
                  style="animation-duration: 1s"
                  enter-active-class="animated bounceInDown"
                >
                  <div v-if="searchBoxAnimation" class="row full-width q-mb-md justify-around items-stretch">

                    <!-- search input -->
                    <div
                      class="row col q-mx-md q-px-md border-radius bg-white items-center text-primary">
                      <q-input v-model="search" class="full-width text-h6" input-class="text-black"
                               placeholder="جستجو..."
                               name="search_term_string"
                               label-color="black" debounce="500" autofocus borderless
                               @update:model-value="searchProjects"
                               @keyup.enter="viewSearchResults"/>
                    </div>

                    <!-- close search -->
                    <q-btn class="col-auto border-radius q-pa-md text-white" size="xl"
                           icon="la la-times" color="primary"
                           unelevated @click="searchBoxAnimation = false; isSearchActive = false"/>

                  </div>
                </transition>

                <!-- search results, view all results -->
                <div v-if="showResults" class="column q-pl-md">

                  <!-- results -->
                  <div v-if="searchResults.length > 0" class="column full-width q-mb-md q-gutter-y-md">
                    <router-link v-for="result of searchResults.filter(x => x.link !== $route.path).slice(0, 4)"
                                 style="text-decoration: none" tag="div"
                                 :to="result.link">
                      <div
                        class="row full-width border-radius bg-white q-pa-md justify-between items-center text-grey-6 cursor-pointer hover-primary"
                        style="border-color: #F1185C">

                        <!-- category, details -->
                        <div class="row items-center">

                          <!-- category -->
                          <div class="column items-center q-mr-lg">

                            <!-- icon -->
                            <q-icon class="border-radius border-solid q-pa-xs"
                                    :name="result.type === 'Project' ? 'la la-tasks' : 'la la-user-friends'" size="md"
                                    color="primary"/>

                            <!-- title -->
                            <div class="text-subtitle2 text-weight-regular text-primary q-mt-xs">
                              {{ result.type === 'Project' ? 'پویش' : 'خیریه' }}
                            </div>

                          </div>

                          <!-- details -->
                          <div class="column">

                            <!-- title -->
                            <div class="text-h6 text-weight-regular text-grey-9 q-mb-sm">
                              {{ result.title }}
                            </div>

                            <!-- category -->
                            <div class="text-subtitle2 text-weight-regular text-grey-7">
                              {{ result.category }}
                            </div>

                          </div>

                        </div>

                        <!-- view item -->
                        <q-icon name="la la-angle-left" size="lg"/>

                      </div>
                    </router-link>
                  </div>

                  <!-- view all results -->
                  <q-btn v-if="searchResults.filter(x => x.link !== $route.path).length > 0"
                         class="full-width border-radius text-subtitle1 text-weight-light" size="xl"
                         color="primary"
                         label="مشاهده همه نتایج" unelevated @click="viewSearchResults"/>

                  <!-- no results -->
                  <div v-else
                       class="row full-width border-radius bg-white q-pa-md q-gutter-x-md justify-center items-center text-primary">

                    <!-- icon -->
                    <q-icon name="la la-exclamation-circle" size="md"/>

                    <!-- info -->
                    <div class="text-h6 text-weight-regular">
                      موردی یافت نشد
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <!-- search layout -->

          </q-dialog>

          <!-- separator (desktop) -->
          <q-separator v-if="!isMobile && $route.name !== 'search' && tenantInfo.id === 18" class="q-mx-md q-my-xs" vertical/>

          <!-- support -->
          <!--          <q-btn v-if="isMobile" class="q-mr-md border-radius text-cyan-7" size="md" icon="la la-headset" unelevated-->
          <!--                 dense @click="$refs.chatDialog.show()"/>-->

          <!-- charities panel, profile, login / signup (desktop) -->
          <div v-if="!isMobile">

            <!-- charities panel -->
            <a v-if="tenantInfo && tenantInfo.id === 18" class="q-mx-md" style="text-decoration: none" href="https://my.hamiane.com" target="_blank">
              <q-btn class="border-radius q-px-md" color="primary" label="پنل موسسات" unelevated dense/>
            </a>

            <!-- profile -->
            <router-link v-if="$store.state.user" style="text-decoration: none" tag="div" to="/profile">
              <q-btn class="border-radius text-weight-regular q-px-md" color="primary"
                     label="حساب کاربری"
                     icon-right="la la-user" outline
                     dense/>
            </router-link>

            <!-- login / register -->
            <router-link v-else style="text-decoration: none" tag="div" to="/login">
              <q-btn class="border-radius q-px-md" color="primary" label="ورود / ثبت‌نام" outline dense/>
            </router-link>

          </div>

        </div>

      </div>
    </q-header>

    <!-- page -->
    <q-page-container v-show="!isLoading" :class="['bg-grey-2', isMobile ? '' : '']">
      <router-view/>
    </q-page-container>

    <!-- footer (desktop) -->
    <q-footer id="footer" v-if="!isLoading && !isMobile && !noDesktopFooterPages.includes($route.name)"
              class="bg-white text-grey-10 items-center print-hide"
              style="border-top: 1px solid #E1E1E1">
      <div class="row full-width justify-center">
        <div :class="['column col-12 col-md-11', isMobile ? 'q-px-md q-py-md q-gutter-y-md' : 'q-py-lg q-gutter-y-lg']">

          <!-- about, links, contact, enamad -->
          <div class="row full-width q-px-sm justify-between items-start">

            <!-- about -->
            <div class="column col-5">

              <!-- title -->
              <div class="text-subtitle1 text-bold q-mb-lg">
                {{ tenantInfo && tenantInfo.title }}
              </div>

              <!-- about -->
              <div class="text-subtitle2 text-weight-light text-justify q-mb-md" style="line-height: 28px">
                <div v-if="tenantInfo" v-html="tenantInfo.about" class="line-clamp-5 full-width ck-content"/>
              </div>

            </div>

            <!-- organization -->
            <div class="column col-auto">

              <!-- title -->
              <div class="text-subtitle1 text-bold q-mb-lg">
                {{  tenantInfo.id === 18 ? 'خدمات مشتریان' : 'دسترسی سریع' }}
              </div>

              <!-- links -->
              <div class="column q-gutter-y-md">
                <router-link v-for="link of (tenantInfo.id === 18 ? organization : charity)" style="text-decoration: none" tag="div" :to="link.link">
                  <div class="row items-center hover-primary cursor-pointer text-grey-10">

                    <!-- icon -->
                    <q-icon name="la la-angle-left" size="10px"/>

                    <!-- title -->
                    <div class="text-subtitle2 text-weight-light q-ml-sm">
                      {{ link.title }}
                    </div>

                  </div>
                </router-link>
              </div>

            </div>

            <!-- projects -->
            <div class="column col-auto">

              <!-- title -->
              <div class="text-subtitle1 text-bold q-mb-lg">

                {{  tenantInfo.id === 18 ? 'با حامیانه' : 'پیوندها' }}
              </div>

              <!-- links -->
              <div class="column q-gutter-y-md">
                <router-link v-for="link of projects" style="text-decoration: none" tag="div" :to="link.link">
                  <div class="row items-center hover-primary cursor-pointer text-grey-10">

                    <!-- icon -->
                    <q-icon name="la la-angle-left" size="10px"/>

                    <!-- title -->
                    <div class="text-subtitle2 text-weight-light q-ml-sm">
                      {{ link.title }}
                    </div>

                  </div>
                </router-link>
              </div>

            </div>

            <!-- contact, enamad -->
            <div class="column col-auto">

              <!-- title -->
              <div class="text-subtitle1 text-bold q-mb-lg">
                تماس با ما
              </div>

              <!-- contact info -->
              <div class="column q-mb-lg q-gutter-y-lg">

                <!-- address -->
                <div v-if="tenantInfo && tenantInfo.details.siteAddress" class="row items-center">

                  <!-- icon -->
                  <q-icon name="la la-map-marker" size="sm"/>

                  <!-- address -->
                  <div class="text-subtitle2 text-weight-light q-ml-sm">
                    {{ tenantInfo && tenantInfo.details.siteAddress }}
                  </div>

                </div>

                <!-- phone -->
                <div v-if="tenantInfo && tenantInfo.details.siteSupportPhone" class="row items-center">

                  <!-- icon -->
                  <q-icon name="la la-phone-alt" size="sm"/>

                  <!-- phone -->
                  <div class="text-subtitle2 text-weight-light q-ml-sm">
                    {{ tenantInfo && tenantInfo.details.siteSupportPhone }}
                  </div>

                </div>

                <!-- email -->
                <div v-if="tenantInfo && tenantInfo.details.siteEmail" class="row q-gutter-x-md items-center">

                  <!-- icon -->
                  <q-icon name="la la-at" size="sm" color="grey"/>

                  <!-- email -->
                  <div class="text-subtitle2 text-weight-regular text-grey-9">
                    {{ tenantInfo && tenantInfo.details.siteEmail }}
                  </div>

                </div>

              </div>

              <!-- enamad -->
              <div v-if="tenantInfo && tenantInfo.details.enamad"
                   :class="['q-ml-none', isMobile ? 'col' : 'logo-width']">
                <div class="border-radius border bg-white q-pa-xs cursor-pointer text-grey-4 "
                     v-html="tenantInfo && tenantInfo.details.enamad">
                </div>

              </div>

            </div>

          </div>

          <!-- certificates -->
<!--          <div :class="['row full-width justify-center items-center q-mt-lg', isMobile ? 'q-gutter-x-md' : 'q-gutter-x-lg']">-->

<!--            &lt;!&ndash; enamad &ndash;&gt;-->
<!--            <div v-if="tenantInfo && tenantInfo.details.enamad"-->
<!--                 :class="['q-ml-none', isMobile ? 'col' : 'logo-width']">-->
<!--              <div class="border-radius border bg-white q-pa-xs cursor-pointer text-grey-4 "-->
<!--                   v-html="tenantInfo && tenantInfo.details.enamad">-->
<!--              </div>-->

<!--            </div>-->


<!--          </div>-->

          <q-separator color="grey-4"/>

          <!-- legal notice -->
          <div :class="['row full-width flex items-center justify-between']">
            <div v-if="tenantInfo && tenantInfo.id === 18" class="row text-subtitle2 text-weight-light text-grey-9">
              <span>تمامی حقوق برای</span>
              <a href="#"
                 :class="['q-mx-xs text-bold text-primary-hover', isMobile ? 'text-primary' : 'text-grey-9']"
                 style="color: black; text-decoration: none">{{ tenantInfo.title }}</a>
              <span>محفوظ می باشد</span>
              <div class="q-ml-xs">
                <span> طراحی و توسعه توسط </span>
                <a href="https://www.saanaa.com"
                   class="text-bold text-grey-9 text-primary-hover"
                   style="color: black; text-decoration: none">داده پردازی سانا</a>
                - نسخه: {{ version }}
              </div>
            </div>

            <div v-if="tenantInfo && tenantInfo.id !== 18" class="row text-subtitle2 text-weight-light text-grey-9">
              <span>تمامی حقوق برای</span>
              <a href="#"
                 :class="['q-mx-xs text-bold text-primary-hover', isMobile ? 'text-primary' : 'text-grey-9']"
                 style="color: black; text-decoration: none">{{ tenantInfo.title }}</a>
             <span>محفوظ می باشد</span>
              <div class="q-ml-xs">
                <span> طراحی و توسعه توسط </span>
                 <a href="https://www.hamiane.com"
                    class="text-bold text-grey-9 text-primary-hover"
                    style="color: black; text-decoration: none">حامیانه</a>
                - نسخه: {{ version }}
              </div>
            </div>

            <!-- social media -->
            <div class="justify-between items-center">

              <!-- disclaimer -->
              <div v-if="isMobile" class="text-subtitle1 text-weight-light text-black">
                ما را دنبال کنید
              </div>

              <!-- links -->
              <div class="row q-gutter-x-lg items-center">

                <!-- telegram-->
                <a href="https://t.me/hamiane_com" target="_blank" style="text-decoration: none">
                  <q-icon class="text-grey-9 text-primary-hover" name="la la-telegram" size="26px">
                    <q-tooltip class="border-radius bg-black text-caption">
                      تلگرام
                    </q-tooltip>
                  </q-icon>
                </a>

                <!-- twitter-->
                <a href="https://twitter.com/hamiane_com" target="_blank" style="text-decoration: none">
                  <q-icon class="text-grey-9 text-primary-hover" name="la la-twitter" size="26px">
                    <q-tooltip class="border-radius bg-black text-caption">
                      توییتر
                    </q-tooltip>
                  </q-icon>
                </a>

                <!-- instagram-->
                <a href="https://instagram.com/hamiane_com" target="_blank" style="text-decoration: none">
                  <q-icon class="text-grey-9 text-primary-hover" name="la la-instagram" size="26px">
                    <q-tooltip class="border-radius bg-black text-caption">
                      اینستاگرام
                    </q-tooltip>
                  </q-icon>
                </a>

              </div>

            </div>
          </div>

        </div>
      </div>
    </q-footer>

    <!-- footer (mobile) -->
    <q-footer id="footer" v-if="!isLoading && isMobile && !noMobileFooterPages.includes($route.name)"
              class="sticky-bottom full-width">
      <div class="column full-width">

        <!-- update disclaimer -->
        <div v-if="needsUpdate">

          <!-- web -->
          <div v-if="appType === 4 & updateAppDisclaimer === true"
               class="row full-width q-pa-sm justify-between items-center text-white"
               style="z-index: 10; background-color: #000000dd">

            <!-- text -->
            <div class="text-subtitle2 text-weight-light">
              نسخه جدید در دسترس است
            </div>

            <!-- update, close button -->
            <div class="row items-center">

              <!-- button -->
              <q-btn class="q-mr-sm q-px-md" label="بروزرسانی" outline dense @click="refreshPage"/>

              <!-- close -->
              <q-btn class="q-px-md" text-color="white" label="بعدا" outline
                     dense @click="updateAppDisclaimer = false"/>

            </div>

          </div>

          <!-- app -->
          <div v-else-if="appType === 1 && updateAppDisclaimer === true"
               :class="['column full-width q-px-md items-center', showLinks ? 'links-shown q-pt-md q-pb-lg' : 'links-hidden q-py-sm']"
               style="z-index: 10; background-color: #000000dd">

            <!-- header -->
            <div class="row full-width justify-between items-center" @click="showLinks = !showLinks">

              <!-- logo, title -->
              <div class="row items-center">

                <!-- logo -->
                <q-img src="img/logo-mobile.png" width="36px"/>

                <!-- title -->
                <div class="text-subtitle1 text-weight-light text-white q-ml-md">
                  نسخه جدید در دسترس است
                </div>

              </div>

              <!-- close -->
              <q-btn size="sm" text-color="white" icon="la la-times" flat
                     dense @click="updateAppDisclaimer = false"/>

            </div>

            <!-- divider, links -->
            <div v-if="showLinks && tenantInfo.id === 18" class="column full-width">

              <!-- divider -->
              <q-separator class="full-width q-my-md" color="grey-6"/>

              <!-- links -->
              <div class="row full-width justify-between items-center">

                <!-- title -->
                <div class="text-subtitle1 text-weight-light text-white">
                  دریافت از کافه‌بازار
                </div>

                <!-- bazaar -->
                <div class="col-6">
                  <a href="https://cafebazaar.ir/app/com.saanaa.hamiane" target="_blank">
                    <q-img class="full-width" src="img/bazaar-badge.svg"/>
                  </a>
                </div>

                <!-- myket -->
                <!--                <div class="col-6 q-pl-xs">-->
                <!--                  <a href="https://myket.ir/app/com.saanaa.hamiane" target="_blank">-->
                <!--                    <q-img class="full-width" src="img/myket-badge.svg"/>-->
                <!--                  </a>-->
                <!--                </div>-->

              </div>

            </div>

          </div>

        </div>

        <!-- install app disclaimer -->
        <div v-else-if="!$q.platform.is.cordova && $route.name === 'home' && installAppDisclaimer === true"
             :class="['column full-width q-px-md items-center', showLinks ? 'links-shown q-py-md' : 'links-hidden q-py-sm']"
             style="z-index: 10; background-color: #000000dd">

          <!-- header -->
          <div class="row col-auto full-width justify-between items-center" @click="showLinks = !showLinks">

            <!-- logo, title -->
            <div class="row items-center">

              <!-- logo -->
              <q-img src="img/logo-mobile.png" width="36px"/>

              <!-- title -->
              <div class="text-subtitle1 text-weight-light text-white q-ml-md">
                دانلود اپلیکیشن حامیانه
              </div>

            </div>

            <!-- close -->
            <q-btn size="sm" text-color="white" icon="la la-times" class="q-pr-md" flat dense @click="installAppDisclaimer = false"/>

          </div>

          <!-- divider, links -->
          <div v-if="showLinks" class="column full-width">

            <!-- divider -->
            <q-separator class="full-width q-my-md" color="grey-6"/>

            <!-- links -->
            <div class="row full-width justify-between items-center">

              <!-- title -->
              <div class="text-subtitle1 text-weight-light text-white">
                دریافت از کافه‌بازار
              </div>

              <!-- bazaar -->
              <div class="col-6">
                <a href="https://cafebazaar.ir/app/com.saanaa.hamiane" target="_blank">
                  <q-img class="full-width" src="img/bazaar-badge.svg"/>
                </a>
              </div>

              <!-- myket -->
              <!--              <div class="col-6 q-pl-xs">-->
              <!--                <a href="https://myket.ir/app/com.saanaa.hamiane" target="_blank">-->
              <!--                  <q-img class="full-width" src="img/myket-badge.svg"/>-->
              <!--                </a>-->
              <!--              </div>-->

            </div>

          </div>

        </div>

        <!-- navigation -->
        <div class="row full-width shadow-2 bg-white q-px-lg q-py-xs justify-between items-center"
             style="z-index: 1000;border-top: 1px solid #E1E1E1">
          <router-link v-for="tab of tabs" style="text-decoration: none" tag="div" :to="getLink(tab)">
            <div :class="['column items-center', activeTab.includes(tab.name) ? 'text-primary' : 'text-black']">

              <!-- icon -->
              <q-icon :name="tab.icon" size="22px"/>

              <!-- title -->
              <div class="text-caption">
                {{ tab.title }}
              </div>

            </div>
          </router-link>
        </div>

      </div>
    </q-footer>

    <!-- live chat (desktop) -->
    <!--    <div v-if="!isMobile" class="fixed-bottom-left" style="z-index: 10000; bottom: 24px; right: 24px">-->

    <!--      <div v-if="!showChatWindow" class="row full-width justify-start">-->
    <!--        <q-fab class="animated fadeInUp" color="primary" icon="la la-headset" direction="up"-->
    <!--               @click="showChatWindow = true"/>-->
    <!--      </div>-->

    <!--      <chat v-else @close="showChatWindow = false"/>-->

    <!--    </div>-->

    <!-- live chat -->
    <q-dialog ref="chatDialog" style="z-index: 10000000" transition-show="jump-up" transition-hide="jump-down"
              maximized>
      <chat @close="$refs.chatDialog.hide()"/>
    </q-dialog>

    <!-- offline state notice -->
    <q-dialog v-if="$q.platform.is.cordova" class="border-radius bg-grey-1" style="z-index: 10000000"
              v-model="connectionDialog"
              maximized>
      <div class="column border-radius bg-white q-pa-lg justify-center items-center">

        <!-- image -->
        <q-img src="img/no-internet.svg"/>

        <!-- info -->
        <div class="q-mt-xl text-subtitle1 text-weight-regular">
          لطفا اتصال دستگاه به اینترنت را بررسی کنید
        </div>

      </div>
    </q-dialog>

  </q-layout>
</template>

<script>
import {version} from '../../package.json'
import Chat from "../components/layout/Chat";
import {LocalStorage, useMeta} from "quasar";
import { setCssVar } from 'quasar'

export default {
  async preFetch({store, currentRoute, previousRoute, redirect, ssrContext, urlPath, publicPath}) {
    await store.dispatch('clearPreFetch')
  },
  components: {Chat},
  data: () => ({
    baseUrl: process.env.API_URL,
    version,
    appType: 4,
    file: null,
    message: null,
    previewImageSrc: undefined,
    isChatLoading: false,
    needsUpdate: false,
    showChatWindow: false,
    chat: {},
    isSearchActive: false,
    showMore : false,
    search: '',
    searchBoxAnimation: false,
    installAppDisclaimer: true,
    updateAppDisclaimer: false,
    showLinks: false,
    connectionDialog: false,
    contactPhoneNumber: null,
    phoneNumberIsWrong: false,
    continuousValidation: false,
    contactRequested: false,
    searchResults: [],
    noHeaderPages: [
      'login',
      'register',
      'nav',
      'catalog'
    ],
    noMobileHeaderPages: [
      'nav',
      'catalog'
    ],
    noDesktopFooterPages: [
      'login',
      'aboutUs',
      'donate',
      'donateByCharity',
      'donateByProject',
      'serviceIntroduction',
      'contactUs',
      'catalog',
      'nav',
      'search',
      'searchPage',
      'gift',
      'o2',
      'charityRegistration'
    ],
    noMobileFooterPages: [
      'projectInfo',
      'catalog',
      'nav'
    ],
    organization: [
      {id: '1', link: '/pricing', title: 'تعرفه‌ها' },
      {id: '2', link: '/charity-registration', title: 'ثبت‌نام موسسات' },
      {id: '3', link: '/catalog', title: 'کاتالوگ موسسات' },
      {id: '4', link: '/terms', title: 'قوانین و مقررات' },
      {id: '5', link: '/privacy-policy', title: 'حریم شخصی' },
      {id: '6', link: '/faq', title: 'سوالات متداول' },
    ],
    charity: [
      {id: '1', link: '/donate', title: 'مشارکت' },
      {id: '2', link: '/challenges', title: 'پویش ها' },
      {id: '3', link: '/donation-request', title: 'اهدائی' },
      {id: '4', link: '/gift', title: 'یادمانه' },
    ],
    projects: [
      {id: '1', link: '/reports', title: 'فعالیت‌ها' },
      {id: '2', link: '/blog', title: 'بلاگ' },
      {id: '4', link: '/about-us', title: 'درباره ما' },
    ],
    topProjectGroups: [
      {id: '1', link: '/challenges?page=1&filter=projectGroupId,in,3', text: 'تحصیلی' },
      {id: '2', link: '/challenges?page=1&filter=projectGroupId,in,6', text: 'جهیزیه' },
      {id: '3', link: '/challenges?page=1&filter=projectGroupId,in,9', text: 'زندانیان مالی' },
      {id: '4', link: '/challenges?page=1&filter=projectGroupId,in,19', text: 'اشتغال' },
      {id: '5', link: '/challenges?page=1&filter=projectGroupId,in,8', text: 'زنان سرپرست خانوار' },
    ],
    projectGroups: [
      {id: '1', link: '/challenges?page=1&filter=projectGroupId,in,3', text: 'تحصیلی' },
      {id: '2', link: '/challenges?page=1&filter=projectGroupId,in,6', text: 'جهیزیه' },
      {id: '3', link: '/challenges?page=1&filter=projectGroupId,in,9', text: 'زندانیان مالی' },
      {id: '4', link: '/challenges?page=1&filter=projectGroupId,in,19', text: 'اشتغال' },
      {id: '5', link: '/challenges?page=1&filter=projectGroupId,in,8', text: 'زنان سرپرست خانوار' },
    ],
    tabs: [
      {name: 'home', title: 'خانه', icon: 'la la-home', link: '/'},
      {name: 'projectSearch', title: 'پویش‌ها', icon: 'la la-hand-holding-heart', link: '/challenges'},
      {name: 'donate', title: 'مشارکت', icon: 'la la-handshake', link: '/donate'},
      {name: 'gift', title: 'یادمانه', icon: 'la la-gift', link: '/gift'},
      {name: 'profile', title: 'پروفایل', icon: 'la la-user', link: '/login'}
    ]
  }),
  watch: {
    contactPhoneNumber() {
      if (this.continuousValidation) {
        this.validatePhoneNumber()
      }
    }
  },
  computed: {
    isLoading() {
      return !this.$store.state.isPageLoaded
    },
    isMobile() {
      return this.$q.screen.lt.md
    },
    activeTab() {
      return this.$route.name
    },
    showResults() {
      return (this.search !== '')
    },
    tenantInfo() {
      return this.$store.state.tenant
    }
  },
  methods: {
    async searchProjects() {
      if (this.search) {
        let res = await this.$axios.get(`${process.env.API_URL}/charity/Project/search`, {params: {searchValue: this.search}})
        this.searchResults = res.data.result
      }
    },
    viewSearchResults() {
      this.$router.push({path: `/search/${this.search}`})
    },
    getLink(tab) {
      if (tab.name === 'profile') {
        if (this.$store.state.user != null) {
          return '/profile'
        } else {
          return '/login'
        }
      } else {
        return tab.link
      }
    },
    async checkVersion() {
      if (this.$q.platform.is.cordova) {
        this.appType = 1
      } else if (this.$q.platform.is.pwa) {
        this.appType = 3
      }
      const res = await this.$axios.get(`${process.env.API_URL}/Core/Version/check`, {
        params: {
          appType: this.appType,
          version: this.version
        },
        noNotify: true
      })
      this.updateAppDisclaimer = res.data.result
      this.needsUpdate = res.data.result
    },
    refreshPage() {
      location.reload()
    },
    checkConnection() {
      this.connectionDialog = true
      document.addEventListener('online', () => {
        this.connectionDialog = false
        this.refreshPage()
      }, false)
    },
    validatePhoneNumber() {
      this.phoneNumberIsWrong = !RegExp('09(1[0-9]|3[1-9]|2[1-9])-?[0-9]{3}-?[0-9]{4}').test(this.contactPhoneNumber)
      if (this.phoneNumberIsWrong) {
        this.continuousValidation = true
      }
    },
    requestContact() {
      if (this.contactRequested) {
        this.$refs.contactDialog.hide()
        this.contactPhoneNumber = null
        this.contactRequested = false
      } else {
        this.validatePhoneNumber()
        if (!this.phoneNumberIsWrong) {
          this.contactRequested = true
          this.continuousValidation = false
          setTimeout(() => {
            this.$refs.contactDialog.focus()
          }, 100)
        }
      }
    },
    async upload() {
      this.chat.uploadProgress = 1
      let data = new FormData()
      data.append('file', this.chat.file)
      const res = await this.$axios.post(`${process.env.API_URL}/cdn/upload/image`, data, {
        onUploadProgress: progressEvent => {
          this.chat.uploadProgress = progressEvent.loaded / progressEvent.total
        }
      }).catch(err => {
        this.chat.uploadProgress = 0
      })
      if (res.data && res.data.length > 0) {
        this.chat.fileObject = {}
        this.chat.fileObject.fileName = res.data[0].urls[0]
        this.chat.fileObject.alt = res.data[0].urls[0]
        this.chat.fileObject.description = this.chat.message
        this.chat.uploadProgress = 0
        this.chat.file = null
      }
    },
    updateFiles(file) {
      this.chat.file = file
      this.chat.uploadProgress = 0
      if (file != null) {
        const reader = new FileReader();
        reader.onload = e => {
          this.chat.previewImageSrc = e.target.result
        }
        reader.readAsDataURL(file);
      }
    },
    async sendMessage() {
      if (this.chat.file) {
        await this.upload().then(async () => {
          if (this.chat.message == null || this.chat.message.trim() === '') {
            this.chat.message = this.chat.fileObject.fileName.split('/')[this.chat.fileObject.fileName.split('/').length - 1]
          }
        })
      }
      if (this.chat.message == null || this.chat.message.trim() === '') return
      let data = {
        attachment: this.chat.fileObject,
        body: this.chat.message,
        taskId: this.taskId,
        parentId: this.chat.selectedMessages && this.chat.selectedMessages.length && this.chat.selectedMessages[0]
      }
      let res = await this.$axios.post(`${process.env.API_URL}/Task/Task/message`, data).catch(e => {
        console.log(e)
      })
      if (res.data.success && res.data.result > 0) {
        this.chat.message = null
        this.chat.fileObject = null
      }
      // load chat
      this.loadTaskInfo()
    },
    selectFile() {
      this.$refs.file.pickFiles()
    }
  },
  mounted() {
    let tenant = this.$store.getters.getTenantInfo()
    this.tenantInfo = {...this.$store.state.tenant}
    if(tenant.id !== 18) {
      document.getElementById("index-favicon").setAttribute("href", `${process.env.API_URL}/cdn/download/${this.tenantInfo.logo.fileName}`);
      document.getElementById("index-favicon16").setAttribute("href", `${process.env.API_URL}/cdn/download/${this.tenantInfo.logo.fileName}`);
      document.getElementById("index-favicon32").setAttribute("href", `${process.env.API_URL}/cdn/download/${this.tenantInfo.logo.fileName}`);
      document.getElementById("index-favicon96").setAttribute("href", `${process.env.API_URL}/cdn/download/${this.tenantInfo.logo.fileName}`);
      document.getElementById("index-favicon128").setAttribute("href", `${process.env.API_URL}/cdn/download/${this.tenantInfo.logo.fileName}`);
    }
    if(tenant.id === 18)
    {
      // gtag UA script
      let gtagUa = document.createElement('script')
      gtagUa.async = true
      gtagUa.src = 'https://www.googletagmanager.com/gtag/js?id=UA-97177883-8'
      document.head.appendChild(gtagUa)
      let gtagUaScript = document.createElement('script')
      let gtagUaScriptChild = `
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        dataLayer.push(arguments);
      }

      gtag('js', new Date());

      gtag('config', 'UA-97177883-8');
    `
      gtagUaScript.append(gtagUaScriptChild)
      document.head.appendChild(gtagUaScript)

      // gtag GA4 script
      let gtagGA4 = document.createElement('script')
      gtagGA4.async = true
      gtagGA4.src = 'https://www.googletagmanager.com/gtag/js?id=G-H48MY8EXCJ'
      document.head.appendChild(gtagGA4)
      let gtagGA4Script = document.createElement('script')
      let gtagGA4ScriptChild = `
       window.dataLayer = window.dataLayer || [];
       function gtag(){dataLayer.push(arguments);}
       gtag('js', new Date());

       gtag('config', 'G-H48MY8EXCJ');
    `
      gtagGA4Script.append(gtagGA4ScriptChild)
      document.head.appendChild(gtagGA4Script)

      // goftino
      let goftino = document.createElement('script')
      goftino.type = 'text/javascript'
      let goftinoChild = `
      !function () {
        var i = "9D7FUE", a = window, d = document;

        function g() {
          var g = d.createElement("script"), s = "https://www.goftino.com/widget/" + i,
            l = localStorage.getItem("goftino_" + i);
          g.async = !0, g.src = l ? s + "?o=" + l : s;
          d.getElementsByTagName("head")[0].appendChild(g);
        }

        "complete" === d.readyState ? g() : a.attachEvent ? a.attachEvent("onload", g) : a.addEventListener("load", g, !1);
      }();
    `
      goftino.append(goftinoChild)
      document.head.appendChild(goftino)
    }

    this.$nextTick(() => {
      this.$store.commit('setPageLoadState', true)
    })
    document.addEventListener('offline', this.checkConnection, false)
    this.checkVersion()
    //
    // cordova.plugins.notification.local.schedule({
    //   title: 'Design team meeting',
    //   trigger: { in: 30, unit: 'second' }
    // })
  }
}
</script>

<style>
header{
  z-index : 10000;
  min-height: 56px !important;
  max-height: 56px !important;
}
.chat {
  width: 25vw;
  height: 70vh;
}
.sticky-bottom {
  position: fixed;
  bottom: 0;
}
.logo-width {
  width: 80px;
  height: 80px;
}
</style>
