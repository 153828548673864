<template>
  <div
    :class="['column animated fadeInUp border-radius bg-white shadow-8 overflow-hidden', isMobile ? 'full-width full-height' : 'chat']">

    <!-- header -->
    <div class="row full-width col-auto bg-primary q-pa-md justify-between items-center">

      <!-- title -->
      <div class="text-subtitle1 text-weight-regular text-white">
        پشتیبانی آنلاین حامیانه
      </div>

      <!-- close -->
      <q-btn icon="la la-times" color="white" size="sm" flat round @click="closeWindow"/>

    </div>

    <!-- chat -->
    <div class="column col full-width text-black" style="background-image: url('img/chat.png');">

      <!-- messages -->
      <q-scroll-area ref="chatScrollArea"
                     :class="['full-width col q-pa-sm', loading ? 'transparent' : 'opaque-animated']"
                     :thumb-style="thumbStyle"
      >
        <q-chat-message v-for="msg of task && task.chat" :key="JSON.stringify(msg)" class="full-width q-mb-none"
                        bg-color="transparent" :sent="msg.sent">
          <template v-slot:default>
            <div class="column">

              <!-- message -->
              <div v-for="(text, index) of msg.text"
                   :class="['q-px-md q-py-sm', index === msg.text.length - 1 ? undefined : 'q-mb-md', msg.sent ? 'message-receiver bg-grey-3' : 'message-user bg-primary']">

                <!-- text -->
                <div :class="['text-subtitle2 text-weight-regular', msg.sent ? 'text-grey-9' : 'text-white']">
                  {{ text }}
                </div>

              </div>

              <!-- time -->
              <div :class="['row q-mt-xs', msg.sent ? undefined : 'justify-end']">
                <div class="text-caption text-grey-7 text-weight-light">
                  {{ msg.stamp }}
                </div>
              </div>

            </div>
          </template>
        </q-chat-message>
      </q-scroll-area>

      <q-separator/>

      <!--image preview-->
      <div v-if="chat.file" class="bg-grey-2 q-pa-xs rounded-borders col-auto">
        <q-img class="border-radius" :src="chat.previewImageSrc" height="100%" :ratio="16/9" contain/>
      </div>

      <!-- message input -->
      <div class="col-auto full-width q-px-md q-py-xs">

        <!-- message -->
        <q-input v-model="chat.message" class="full-width" dense borderless
                 :placeholder="chat.file ? ' تصویر' : 'متن پیام...'" bg-color="white" @keyup.enter="sendMessage">
          <template v-slot:append>
            <!--            <div class="row q-gutter-x-md items-center">-->

            <!-- add attachment -->
            <!--              <q-icon class="btn rotate-90 cursor-pointer" name="la la-paperclip" size="26px" @click="selectFile"/>-->

            <!-- send -->
            <q-icon class="btn rotate-315 cursor-pointer" name="la la-location-arrow" size="26px"
                    @click="sendMessage"/>

            <!--            </div>-->
          </template>
        </q-input>

        <!-- attachment dialog -->
        <q-file ref="file" v-show="false" :value="chat.file" @input="updateFiles"/>

      </div>

    </div>

  </div>
</template>

<script>
import {LocalStorage} from 'quasar'

export default {
  name: "Chat",
  data: () => ({
    taskId: null,
    task: null,
    showChatWindow: false,
    loading: false,
    chat: {},
    thumbStyle: {opacity: 0}
  }),
  computed: {
    isMobile() {
      return this.$q.screen.lt.md
    },
    userInfo() {
      return this.$store.state.user
    }
  },
  methods: {
    loadTaskInfo() {
      if (this.taskId > 0) {
        this.loading = true
        this.$axios.get(`${process.env.API_URL}/Task/Task/${this.taskId}`)
          .then(res => {
            this.task = res.data.result
            if (res.data.result.chat) {
              let chat = JSON.parse(JSON.stringify(res.data.result.chat || "[]"))
              this.task.chat = []
              let personId = null
              let fillChat = new Promise((resolve, reject) => {
                chat.forEach((msg, index, array) => {
                  if (personId === msg.personId) {
                    this.task.chat[this.task.chat.length - 1].text.push(this.buildMessage(msg.body, msg.attachment))
                    this.task.chat[this.task.chat.length - 1].date = msg.date.toJalaliDateTime()
                  } else {
                    personId = msg.personId
                    this.task.chat.push({
                      name: msg.personTitle,
                      text: [this.buildMessage(msg.body, msg.attachment)],
                      stamp: msg.date.getTime(),
                      sent: msg.personId !== +this.userInfo.sub,
                      avatar: null
                    })
                  }
                  console.log(index)
                  if (index === array.length - 1) {
                    resolve()
                  }
                })
              })
              fillChat.then(() => {
                setTimeout(() => {
                  this.$refs.chatScrollArea.setScrollPercentage('vertical', 1.0, 100)
                  this.loading = false
                }, 300)
              })
            }
            this.loading = false
          }).catch(() => {
          this.loading = false
        })
      }
    },
    selectFile() {
      this.$refs.file.pickFiles()
    },
    updateFiles(file) {
      this.chat.file = file
      this.chat.uploadProgress = 0
      if (file != null) {
        const reader = new FileReader();
        reader.onload = e => {
          this.chat.previewImageSrc = e.target.result
        }
        reader.readAsDataURL(file);
      }
    },
    buildMessage(msg, img) {
      if (img && img.fileName) {
        return `<img src="${process.env.API_URL}/cdn/download/${img.fileName}" alt="${img.alt}" style="max-width:150px" class="rounded-borders"/><br/>`
          + msg
      } else {
        return msg.toString()
      }
    },
    async upload() {
      this.chat.uploadProgress = 1
      let data = new FormData()
      data.append('file', this.chat.file)
      const res = await this.$axios.post(`${process.env.API_URL}/cdn/upload/image`, data, {
        onUploadProgress: progressEvent => {
          this.chat.uploadProgress = progressEvent.loaded / progressEvent.total
        }
      }).catch(err => {
        this.chat.uploadProgress = 0
      })
      if (res.data && res.data.length > 0) {
        this.chat.fileObject = {}
        this.chat.fileObject.fileName = res.data[0].urls[0]
        this.chat.fileObject.alt = res.data[0].urls[0]
        this.chat.fileObject.description = this.chat.message
        this.chat.uploadProgress = 0
        this.chat.file = null
      }
    },
    async sendMessage() {
      if (this.chat.file) {
        await this.upload().then(async () => {
          if (this.chat.message == null || this.chat.message.trim() === '') {
            this.chat.message = this.chat.fileObject.fileName.split('/')[this.chat.fileObject.fileName.split('/').length - 1]
          }
        })
      }
      if (this.chat.message == null || this.chat.message.trim() === '') return
      let data = {
        taskId: this.taskId,
        attachment: this.chat.fileObject,
        body: this.chat.message
      }
      let res = await this.$axios.post(`${process.env.API_URL}/Task/Task/supportMessage`, data).catch(e => {
        console.log(e)
      })
      if (res.data.success && res.data.result > 0) {
        this.chat.message = null
        this.chat.fileObject = null
      }
      this.taskId = res.data.result
      LocalStorage.set('taskId', res.data.result)
      this.loadTaskInfo()
    },
    closeWindow() {
      this.$emit('close')
    }
  },
  async mounted() {
    this.taskId = LocalStorage.getItem('taskId') || 0
    this.loadTaskInfo()
  }
}
</script>

<style scoped>
.btn {
  color: lightgrey;
  transition: 0.3s;
}

.btn:hover {
  color: grey;
  transition: 0.3s;
}

.chat {
  width: 25vw;
  height: 70vh;
}

.message-receiver {
  border-radius: 0.4rem 0.4rem 0.1rem 0.4rem;
}

.message-user {
  border-radius: 0.4rem 0.4rem 0.4rem 0.1rem;
}

.opaque-animated {
  opacity: 1;
  transition: 0.1s;
}

.transparent {
  opacity: 0;
}
</style>
